import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Grid } from '@mui/material';
import { Box, Typography, TextField, CheckboxField, InputAdornment, RadioField } from '@common/components/';
import { peopleType as peopleTypeMap } from '@apis/usePeopleApi';
import SignBox from '@icoach/components/SignBox';
import clsx from 'clsx';
import { refIsRequiredError } from '@util/utils';
import { setCheckboxValue } from '@icoach/documents/tools';

const baseDatePeopleTypes = [peopleTypeMap.onlyJoin, peopleTypeMap.transferManually];

// int itemValue : 0 = null, 1 = true, 2 = false
// string itemType
// string itemText

const getCheckItemsData = (...targetRef) => {
    return targetRef.reduce((acc, { current }) => {
        const { getResult } = current;
        const t = getResult().map(({ checked, value, key }) => ({ itemValue: checked ? 1 : 2, itemType: value, itemText: key || '' }));
        acc.push(...t);
        return acc;
    }, []);
};

const getTextItemsData = (originalData, ...targetRef) => {
    let targetData = [...originalData];
    targetRef.forEach(({ current }) => {
        const { getResult, getName } = current;
        const itemText = getResult();
        const itemType = getName();
        const t = targetData.find((el) => String(el.itemType) === String(itemType));
        if (!t) {
            targetData.push({
                itemValue: 0,
                itemType: itemType,
                itemText: itemText,
            });
        }
    });
    return targetData;
};

const HealthDeclarationContent103 = React.forwardRef((props, ref) => {
    const { sourceData, peopleType, isReadOnly } = props;
    const [refreshID, setRefreshID] = useState(uuid());
    const dataMapRef = useRef(null);

    const itemDomRef_1 = useRef(null);
    const itemDomRef_2_6 = useRef(null);
    const itemDomRef_7 = useRef(null);
    const itemDomRef_8 = useRef(null);
    const itemDomRef_9 = useRef(null);
    const itemDomRef_10 = useRef(null);
    const itemDomRef_11 = useRef(null);
    const itemDomRef_text_12 = useRef(null);
    const itemDomRef_text_13 = useRef(null);
    const itemDomRef_text_14 = useRef(null);
    const itemDomRef_15_21 = useRef(null);
    const itemDomRef_text_22 = useRef(null);
    const itemDomRef_text_23 = useRef(null);
    const itemDomRef_text_24 = useRef(null);
    const itemDomRef_text_25 = useRef(null);
    const itemDomRef_26 = useRef(null);
    const itemDomRef_27 = useRef(null);
    const itemDomRef_28 = useRef(null);
    const itemDomRef_29 = useRef(null);
    const itemDomRef_text_30 = useRef(null);
    const itemDomRef_31 = useRef(null);
    const itemDomRef_text_32 = useRef(null);
    const itemDomRef_text_33 = useRef(null);
    const itemDomRef_text_34 = useRef(null);
    const itemDomRef_text_35 = useRef(null);
    const itemDomRef_text_36 = useRef(null);
    const itemDomRef_37 = useRef(null);
    const signImgRef = useRef(null);
    const displayNameRef = useRef(null);
    const cellPhoneRef = useRef(null);

    const onlyCreate = baseDatePeopleTypes.indexOf(String(peopleType)) !== -1;
    const getBasicDataTitle = () => {
        return peopleType === peopleTypeMap.onlyJoin ? '新入會請填寫基礎資訊，提供入會申請書引用：' : '手動移入請填寫基礎資訊，提供移入申請書引用：';
    };

    const getAllParams = () => {
        let params = {};
        let items = [];
        let paramsPack = getCheckItemsData(
            itemDomRef_1,
            itemDomRef_2_6,
            itemDomRef_7,
            itemDomRef_8,
            itemDomRef_9,
            itemDomRef_10,
            itemDomRef_11,
            itemDomRef_15_21,
            itemDomRef_26,
            itemDomRef_27,
            itemDomRef_28,
            itemDomRef_29,
            itemDomRef_31
        );
        paramsPack = getTextItemsData(
            paramsPack,
            itemDomRef_text_12,
            itemDomRef_text_13,
            itemDomRef_text_14,
            itemDomRef_text_22,
            itemDomRef_text_23,
            itemDomRef_text_24,
            itemDomRef_text_25,
            itemDomRef_text_30,
            itemDomRef_text_32,
            itemDomRef_text_33,
            itemDomRef_text_34,
            itemDomRef_text_35,
            itemDomRef_text_36
        );

        // 滿意度
        const satisfactionLevel = itemDomRef_37.current.getResult().find(({ checked }) => checked) || {};
        paramsPack.push({
            itemValue: satisfactionLevel?.value ?? 0,
            itemType: '37',
            itemText: satisfactionLevel?.key ?? '',
        });

        if (displayNameRef.current) {
            Object.assign(params, { displayName: displayNameRef.current.getResult() });
        }
        if (cellPhoneRef.current) {
            Object.assign(params, { cellPhone: cellPhoneRef.current.getResult() });
        }

        const signImg = signImgRef.current.getResult();
        items.push(...paramsPack);
        Object.assign(params, { survey: { items } });
        Object.assign(params, { signImg });

        return params;
    };

    const getItemByType = (dataMap, itemType, valueType = 'value') => {
        if (!(dataMap instanceof Map)) return undefined;

        const item = dataMap.get(itemType);
        if (!item) return undefined;

        // 1 = true, 2 = false
        if (valueType === 'value' && String(item.itemValue) === '1') {
            return item.itemType;
        } else if (valueType === 'text') {
            return item.itemText;
        }

        return undefined;
    };

    const filterItemTypesWithValueOne = (dataMap, itemTypes = []) => {
        if (!(dataMap instanceof Map)) return [];

        return itemTypes.filter((itemType) => {
            const item = dataMap.get(itemType);
            return item && String(item.itemValue) === '1';
        });
    };

    const getItemByValue = (dataMap, itemType) => {
        if (!(dataMap instanceof Map)) return undefined;

        const item = dataMap.get(itemType);
        if (!item) return undefined;
        return item.itemValue;
    };

    useEffect(
        () => {
            const createDataMap = (data) => {
                const dataMap = new Map();
                data.forEach((item) => {
                    dataMap.set(item.itemType, item);
                });
                return dataMap;
            };
            dataMapRef.current = createDataMap(sourceData?.survey?.items || []);
            setRefreshID(uuid());
        },
        // eslint-disable-next-line
        [sourceData]
    );

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => refIsRequiredError(signImgRef),
                getResult: () => getAllParams(),
            };
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Box className={clsx(isReadOnly && 'pb-3')}>
                {onlyCreate && (
                    <React.Fragment>
                        <Typography variant="h6" component="h3" className="mb-4">
                            {getBasicDataTitle()}
                        </Typography>
                        <Box className={clsx(isReadOnly && 'mb-3', 'p-3', 'bg-light')}>
                            <Grid spacing={3} container>
                                <Grid item xs={4}>
                                    <TextField key={'displayName'} ref={displayNameRef} label={'顯示名稱'} readOnly={isReadOnly} fullWidth required />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField key={'cellPhone'} ref={cellPhoneRef} label={'手機'} readOnly={isReadOnly} maskType={'MOB'} fullWidth required />
                                </Grid>
                            </Grid>
                        </Box>
                    </React.Fragment>
                )}
                <Typography variant="h6" component="h3" className="mb-4">
                    對大部份人來說，從事安全而規律的運動，能提昇個人體適能。當您欲開始計劃從事較多的身體活動時，請先回答以下問題，這個問卷將會告訴您在開始從事較多的身體活動時，是否應先徵得醫生的指示。請您詳細閱讀下列問題，如有符合的項目請打✓:
                </Typography>
                <React.Fragment key={refreshID}>
                    <Box component="ol" className="questions main">
                        <Box component="li" className="questions-item">
                            醫師告訴過您，您的心臟有些問題，您只能做醫師建議的運動。
                            <CheckboxField ref={itemDomRef_1} defaultValue={setCheckboxValue(getItemByType(dataMapRef.current, 1))} row>
                                <CheckboxField.Checkbox label={'是'} value={'1'} />
                            </CheckboxField>
                        </Box>
                        <Box component="li" className="questions-item">
                            醫生診斷您有以下心血管相關疾病。
                            <CheckboxField ref={itemDomRef_2_6} defaultValue={filterItemTypesWithValueOne(dataMapRef.current, [2, 3, 4, 5, 6])} row>
                                <CheckboxField.Checkbox name="心臟病" label={'心臟病'} value={'2'} />
                                <CheckboxField.Checkbox name="高血壓" label={'高血壓'} value={'3'} />
                                <CheckboxField.Checkbox name="糖尿病" label={'糖尿病'} value={'4'} />
                                <CheckboxField.Checkbox name="高血脂肪" label={'高血脂肪'} value={'5'} />
                                <CheckboxField.Checkbox name="膽固醇過高" label={'膽固醇過高'} value={'6'} />
                            </CheckboxField>
                        </Box>
                        <Box component="li" className="questions-item">
                            您正在服用高血壓或心臟疾病的藥物(醫師處方)。
                            <CheckboxField ref={itemDomRef_7} defaultValue={setCheckboxValue(getItemByType(dataMapRef.current, 7))} row>
                                <CheckboxField.Checkbox label={'是'} value={'7'} />
                            </CheckboxField>
                        </Box>
                        <Box component="li" className="questions-item">
                            您活動時會感到胸悶或胸痛。
                            <CheckboxField ref={itemDomRef_8} defaultValue={setCheckboxValue(getItemByType(dataMapRef.current, 8))} row>
                                <CheckboxField.Checkbox label={'是'} value={'8'} />
                            </CheckboxField>
                        </Box>
                        <Box component="li" className="questions-item">
                            過去幾個月以來，您曾在未活動的情況下出現胸悶的情況。
                            <CheckboxField ref={itemDomRef_9} defaultValue={setCheckboxValue(getItemByType(dataMapRef.current, 9))} row>
                                <CheckboxField.Checkbox label={'是'} value={'9'} />
                            </CheckboxField>
                        </Box>
                        <Box component="li" className="questions-item">
                            您曾有因暈眩而失去平衡或意識的情況。
                            <CheckboxField ref={itemDomRef_10} defaultValue={setCheckboxValue(getItemByType(dataMapRef.current, 10))} row>
                                <CheckboxField.Checkbox label={'是'} value={'10'} />
                            </CheckboxField>
                        </Box>
                        <Box component="li" className="questions-item">
                            您是否有心血管疾病家族史。
                            <CheckboxField ref={itemDomRef_11} defaultValue={setCheckboxValue(getItemByType(dataMapRef.current, 11))} row>
                                <CheckboxField.Checkbox label={'是'} value={'11'} />
                            </CheckboxField>
                            <Grid spacing={1} container>
                                <Grid xs={12} item>
                                    <TextField
                                        name="12"
                                        label="稱謂"
                                        className="flex-y-flex-start"
                                        LabelPaddingRightProps={{ value: 44 }}
                                        direction="row"
                                        ref={itemDomRef_text_12}
                                        defaultValue={getItemByType(dataMapRef.current, 12, 'text')}
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <TextField
                                        name="13"
                                        label="發病年齡"
                                        className="flex-y-flex-start"
                                        direction="row"
                                        ref={itemDomRef_text_13}
                                        type="number"
                                        defaultValue={getItemByType(dataMapRef.current, 13, 'text')}
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <TextField
                                        name="14"
                                        label="病症"
                                        className="flex-y-flex-start"
                                        LabelPaddingRightProps={{ value: 44 }}
                                        direction="row"
                                        ref={itemDomRef_text_14}
                                        defaultValue={getItemByType(dataMapRef.current, 14, 'text')}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box component="li" className="questions-item">
                            醫生診斷您有骨關節相關疾病：
                            <CheckboxField
                                ref={itemDomRef_15_21}
                                defaultValue={filterItemTypesWithValueOne(dataMapRef.current, [15, 16, 17, 18, 19, 20, 21])}
                                row
                            >
                                <CheckboxField.Checkbox name="骨質疏鬆症" label={'骨質疏鬆症'} value={'15'} />
                                <CheckboxField.Checkbox name="五十肩" label={'五十肩'} value={'16'} />
                                <CheckboxField.Checkbox name="腕隧道症候群" label={'腕隧道症候群'} value={'17'} />
                                <CheckboxField.Checkbox name="退化性膝關節炎" label={'退化性膝關節炎'} value={'18'} />
                                <CheckboxField.Checkbox name="椎間盤突出" label={'椎間盤突出'} value={'19'} />
                                <CheckboxField.Checkbox name="脊椎滑脫" label={'脊椎滑脫'} value={'20'} />
                                <CheckboxField.Checkbox label={'任何膝關節受損之病症'} value={'21'} />
                                <TextField name="22" defaultValue={getItemByType(dataMapRef.current, 22, 'text')} ref={itemDomRef_text_22} />
                            </CheckboxField>
                        </Box>
                        <Box component="li" className="questions-item">
                            您有上述以外的肌肉、關節、下背部疼痛等相關病症。請簡單敘述：
                            <TextField name={'23'} ref={itemDomRef_text_23} defaultValue={getItemByType(dataMapRef.current, 23, 'text')} />
                        </Box>
                        <Box component="li" className="questions-item">
                            您有現在仍感到不適的舊傷。請簡單敘述：
                            <TextField name={'24'} ref={itemDomRef_text_24} defaultValue={getItemByType(dataMapRef.current, 24, 'text')} />
                        </Box>
                        <Box component="li" className="questions-item">
                            您有可能因肌力訓練引起惡化的症狀。請簡單敘述：
                            <TextField name={'25'} ref={itemDomRef_text_25} defaultValue={getItemByType(dataMapRef.current, 25, 'text')} />
                        </Box>
                    </Box>
                    <Typography variant="h6" component="h3" className="mb-4">
                        請問您是否有以下狀況?
                    </Typography>
                    <Box component="ol" className="questions main">
                        <Box component="li" className="questions-item">
                            <Box>
                                懷孕/3個月內打算懷孕
                                <CheckboxField ref={itemDomRef_26} defaultValue={setCheckboxValue(getItemByType(dataMapRef.current, 26))} row>
                                    <CheckboxField.Checkbox name="懷孕" label={'是'} value={'26'} />、
                                </CheckboxField>
                            </Box>
                            <Box>
                                更年期
                                <CheckboxField ref={itemDomRef_27} defaultValue={setCheckboxValue(getItemByType(dataMapRef.current, 27))} row>
                                    <CheckboxField.Checkbox name="更年期" label={'是'} value={'27'} />
                                </CheckboxField>
                            </Box>
                        </Box>
                        <Box component="li" className="questions-item">
                            過去一年內有動過手術
                            <CheckboxField ref={itemDomRef_28} defaultValue={setCheckboxValue(getItemByType(dataMapRef.current, 28))} row>
                                <CheckboxField.Checkbox label={'是'} value={'28'} />
                            </CheckboxField>
                        </Box>
                        <Box component="li" className="questions-item">
                            呼吸道或肺部疾病(例如：氣喘)
                            <CheckboxField ref={itemDomRef_29} defaultValue={setCheckboxValue(getItemByType(dataMapRef.current, 29))} row>
                                <CheckboxField.Checkbox label={'是'} value={'29'} />
                                <TextField
                                    name="30"
                                    ref={itemDomRef_text_30}
                                    direction="row"
                                    label="症狀名"
                                    defaultValue={getItemByType(dataMapRef.current, 30, 'text')}
                                />
                            </CheckboxField>
                            甲狀腺疾病
                            <CheckboxField ref={itemDomRef_31} defaultValue={setCheckboxValue(getItemByType(dataMapRef.current, 31))} row>
                                <CheckboxField.Checkbox label={'是'} value={'31'} />
                                <TextField
                                    name="32"
                                    ref={itemDomRef_text_32}
                                    direction="row"
                                    label="症狀名"
                                    defaultValue={getItemByType(dataMapRef.current, 32, 'text')}
                                />
                            </CheckboxField>
                        </Box>
                        <Box component="li" className="questions-item">
                            目前定期去醫院看病
                            <TextField
                                name="33"
                                ref={itemDomRef_text_33}
                                className="flex-y-flex-start"
                                direction="row"
                                label="原因"
                                defaultValue={getItemByType(dataMapRef.current, 33, 'text')}
                            />
                        </Box>
                        <Box component="li" className="questions-item">
                            正在服用治療性質之藥物
                            <TextField
                                name="34"
                                ref={itemDomRef_text_34}
                                className="flex-y-flex-start"
                                direction="row"
                                label="藥物名稱"
                                defaultValue={getItemByType(dataMapRef.current, 34, 'text')}
                            />
                        </Box>
                        <Box component="li" className="questions-item">
                            有抽菸的習慣
                            <TextField
                                ref={itemDomRef_text_35}
                                className="flex-y-flex-start"
                                direction="row"
                                name="35"
                                label="菸量"
                                InputProps={{ endAdornment: <InputAdornment position="start">支/天</InputAdornment> }}
                                type="number"
                                defaultValue={getItemByType(dataMapRef.current, 35, 'text')}
                            />
                        </Box>
                        <Box component="li" className="questions-item">
                            有飲酒的習慣
                            <TextField
                                ref={itemDomRef_text_36}
                                className="flex-y-flex-start"
                                name="36"
                                direction="row"
                                label="次數"
                                InputProps={{ endAdornment: <InputAdornment position="start">次/週</InputAdornment> }}
                                type="number"
                                defaultValue={getItemByType(dataMapRef.current, 36, 'text')}
                            />
                        </Box>
                        <Box component="li" className="questions-item">
                            您的工作生活情緒/壓力度:
                            <RadioField
                                ref={itemDomRef_37}
                                name={'37'}
                                defaultValue={getItemByValue(dataMapRef.current, 37)}
                                row
                                labelDirection="row"
                                origin={'end'}
                            >
                                <RadioField.Radio name="非常輕鬆" value={5} label="非常輕鬆" />
                                <RadioField.Radio name="輕鬆" value={4} label="輕鬆" />
                                <RadioField.Radio name="輕微緊張" value={3} label="輕微緊張" />
                                <RadioField.Radio name="中度緊張" value={2} label="中度緊張" />
                                <RadioField.Radio name="非常緊張" value={1} label="非常緊張" />
                            </RadioField>
                        </Box>
                    </Box>
                </React.Fragment>
                <Typography className="mb-4">
                    Curves的環狀訓練是專為女性設計的運動，並經過科學認證的安全有效的油壓式阻力訓練。如果您在以上的回答中有疑慮，或已超過69歲以上，並不常活動，請在增加活動量前，先詢問醫師的意見。
                </Typography>
            </Box>
            {!isReadOnly && (
                <React.Fragment>
                    <Box className="pb-3">
                        <Typography variant="h3" className="h6 mb-1 mt-5">
                            確認書
                        </Typography>
                        <Typography className="mark">請確實確認內容後, 在本確認書上簽名。</Typography>
                        <Box component="ol">
                            <Box component="li">
                                本人對自我健康狀況充分了解且負責，在參加此課程之前，就已對身體狀況諮詢過醫生的意見，確認能夠負荷 Curves各項設施。
                            </Box>
                            <Box component="li">
                                在訓練的過程中，本人會隨時注意自己的身體狀況，如果感到任何不舒服或不正常，如肌肉拉傷、軟組織挫傷、暈眩、心臟不適等，本人會立即停止練習，並將狀況告知現場教練；若有任何隱瞞情事或尊守醫師與教練建議指示，本人願自行承擔所有責任，並同意不向Curves總部及其加盟店、教練究責。
                            </Box>
                        </Box>
                    </Box>
                    <Grid className="pb-3" container>
                        <Grid xs={6} item>
                            <Typography variant="body2" className="font-color-3">
                                未滿十八歲者請由監護人簽名
                            </Typography>
                            <SignBox ref={signImgRef} title="簽名" required />
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
});

export default HealthDeclarationContent103;
