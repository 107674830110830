import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { effectTypeMap } from '@icoach/documents/cyclePurchase/staticData';
import useMessageDialog from '@util/hook/useMessageDialog';
import { v4 as uuid } from 'uuid';
import { getRadioFieldResult, refIsRequiredError, isEmpty } from '@util/utils';
import { Alert, Button, Card, CheckboxField } from '@common/components/';
import { Grid } from '@mui/material';
import { setCheckboxValue } from '@icoach/documents/tools';
import { CreditCard } from '@icoach/documents/components';
import { SectionHeader, useSectionEffect } from '@icoach/documents/cyclePurchase/components/CyclePurchaseUtility';

const PaymentSection = forwardRef((props, ref) => {
    const {
        title = '付款資訊',
        sourceData = {},
        membershipData,
        errorModule,
        isCreate = false,
        setShowTip,
        onChangeEffectType: onChangeEffectTypeProps,
    } = props;
    const { noDeductNow } = sourceData;
    const effectTypeID = effectTypeMap.payment;
    const setMessageDialog = useMessageDialog();
    const [isShow, setShow] = useState(Boolean(isCreate));
    const [defaultData, setDefaultData] = useState({
        source: {},
        key: uuid(),
    });
    const pickItemRef = useRef(null);
    const creditCardRef = useRef(null);
    const isPaymentRef = useRef(null);

    const getPaymentError = useCallback((errorModule) => {
        const { payment, cardSignImg } = errorModule || {};
        let paymentError = isEmpty(payment) ? {} : { ...payment };

        if (cardSignImg) {
            paymentError = {
                ...paymentError,
                signImg: cardSignImg,
            };
        }

        return isEmpty(paymentError) ? null : paymentError;
    }, []);

    const getResult = () => {
        const creditData = creditCardRef.current && creditCardRef.current.getResult();
        let result = {
            paymentType: 1, // 2022/11/10 只提供信用卡
        };

        if (!isEmpty(creditData)) {
            const { signImg, ...other } = creditData;
            result.payment = other;
            result.cardSignImg = signImg;
        }
        if (isPaymentRef.current && isPaymentRef.current.getResult) result.noDeductNow = getRadioFieldResult(isPaymentRef.current.getResult(), 'checked');

        return result;
    };

    const handleOverwriteOnClick = () => {
        let payment, cardRelation;

        if (!isEmpty(membershipData)) {
            payment = membershipData.payment;
            cardRelation = payment && payment.cardRelation;
        }
        if (isEmpty(membershipData) || cardRelation !== '本人') {
            setMessageDialog({
                open: true,
                title: '提醒',
                msg: '很抱歉！無法取得相關資料，可能是沒有設定常用信用卡或不是信用卡持卡人，謝謝。',
            });
        } else {
            setDefaultData({ source: payment, key: uuid() });
        }
    };

    const handleShowContent = (checked) => {
        setShow(checked);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => refIsRequiredError(creditCardRef),
            getEffectTypeID: () => isShow && effectTypeID,
            setHelperText: (isError, text) => {
                return pickItemRef.current && pickItemRef.current.setHelperText(isError, text);
            },
        }),
        // eslint-disable-next-line
        [isShow],
    );

    useSectionEffect(setShowTip, onChangeEffectTypeProps, isShow, effectTypeID);

    useEffect(
        () => {
            if (!isEmpty(sourceData)) {
                if (sourceData.payment) {
                    setDefaultData({
                        source: sourceData.payment,
                        key: uuid(),
                    });
                }
                if (!isEmpty(sourceData.effectType)) {
                    setShow(sourceData.effectType.includes(effectTypeID));
                }
            }
        },
        // eslint-disable-next-line
        [sourceData],
    );

    return (
        <React.Fragment>
            <SectionHeader title={title} isShowCheckBox={!isCreate} refItem={pickItemRef} handleChange={handleShowContent} defaultChecked={isShow} />
            {isShow && (
                <Card.CardContent key={defaultData.key} className={'p-4'}>
                    <Grid spacing={3} container>
                        <Grid xs={12} item>
                            <div className="tool-btn-group">
                                <Button color={'grey'} onClick={handleOverwriteOnClick}>
                                    套用常用信用卡
                                </Button>
                            </div>
                        </Grid>
                        {!isCreate && (
                            <Grid xs={12} item>
                                <CheckboxField key={noDeductNow} ref={isPaymentRef} defaultChecked={setCheckboxValue(noDeductNow)}>
                                    <CheckboxField.Checkbox label={<span className={'font-weight-bold'}>當月不補扣</span>} />
                                </CheckboxField>
                                <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                                    當月補扣，信用卡資訊變更將立即生效，並通知HC單位進行補扣。若有其他選項變更將會以變更生效日為主
                                </Alert>
                            </Grid>
                        )}
                        <Grid xs={12} item>
                            <CreditCard
                                ref={creditCardRef}
                                defaultValue={defaultData.source}
                                errorModule={getPaymentError(errorModule)}
                                isDisplaySignature
                                isShowCardRelation
                                required
                            />
                        </Grid>
                    </Grid>
                </Card.CardContent>
            )}
        </React.Fragment>
    );
});

export default PaymentSection;
