import React, { useRef, useImperativeHandle, useState } from 'react';
import { Grid } from '@mui/material';
import { Typography, RadioField, TextField, Collapse, Box } from '@common/components/';
import { peopleType as peopleTypeMap } from '@apis/usePeopleApi';
import { refIsRequiredError } from '@util/utils';
import MutiRadioTextField from '@icoach/documents/healthDeclaration/MutiRadioTextField';
import SignBox from '@icoach/components/SignBox';
import clsx from 'clsx';

const baseDatePeopleTypes = [peopleTypeMap.onlyJoin, peopleTypeMap.transferManually];

const getItemsValue = (typeNum, key, targetItem) => {
    if (Array.isArray(targetItem)) {
        return (targetItem.find((cur) => String(cur.itemType) === String(typeNum))?.[key] ?? null) || null;
    } else {
        console.error('targetItem param is not Array');
        return null;
    }
};

/**
 * 初始列舉項目的itemType
 * @param {number} typeNum 對應的itemType數字
 * @param {string} targetItem 獲取想要的keyValue
 * @param {array} targetItem
 */
const getItemsData = (typeNum, targetItem) => {
    if (Array.isArray(targetItem)) {
        return targetItem.find((cur) => String(cur.itemType) === String(typeNum));
    } else {
        console.error('targetItem param is not Array');
        return null;
    }
};

const ToggleBox = React.forwardRef((props, ref) => {
    const { children, defalutValue = 0 } = props;

    // 1 表示為 Yes 該目標才打開 (Q:您現在有定期去醫院看病嗎？)
    const [open, setOpen] = useState(String(defalutValue) === '1');

    React.useImperativeHandle(
        ref,
        () => {
            return {
                setOpen: setOpen,
                isOpen: open,
            };
        },
        // eslint-disable-next-line
        [open]
    );

    return (
        <Collapse in={open} key={open}>
            {children}
        </Collapse>
    );
});

const HealthDeclarationContent101 = React.forwardRef((props, ref) => {
    const { sourceData, peopleType, isReadOnly } = props;
    const { displayName, cellPhone } = sourceData;
    const { items = [] } = sourceData?.survey ?? {};
    const basicDisplayNameRef = useRef(null);
    const basicCellPhoneRef = useRef(null);
    const itemRadioRef = React.useRef({});
    const sickNameRef = React.useRef(null);
    const hospitalNameRef = React.useRef(null);
    const hospitalTelRef = React.useRef(null);
    const precautionRef = React.useRef(null);
    const toggleRef = React.useRef(null);
    const signImgRef = React.useRef(null);
    const onlyCreate = baseDatePeopleTypes.indexOf(String(peopleType)) !== -1;

    const getAllParams = () => {
        let paramsPack = { ...sourceData };
        const refPack = itemRadioRef.current || {};
        const sickName = sickNameRef.current;
        const hospitalName = hospitalNameRef.current;
        const hospitalTel = hospitalTelRef.current;
        const precaution = precautionRef.current;
        const signImg = signImgRef.current;
        const itemsPack = Object.keys(refPack).map((itemName) => {
            let targetItemData = refPack[itemName].getResult();
            if (Array.isArray(targetItemData)) {
                targetItemData = targetItemData.find(({ checked }) => checked);
                return {
                    itemType: itemName,
                    itemValue: targetItemData?.value ?? '',
                    itemText: '',
                };
            }
            return targetItemData;
        });
        itemsPack.push({
            itemType: sickName.getName(),
            itemValue: '',
            itemText: sickName.getResult(),
        });
        itemsPack.push({
            itemType: hospitalName.getName(),
            itemValue: '',
            itemText: hospitalName.getResult(),
        });
        itemsPack.push({
            itemType: hospitalTel.getName(),
            itemValue: '',
            itemText: hospitalTel.getResult(),
        });
        itemsPack.push({
            itemType: precaution.getName(),
            itemValue: '',
            itemText: precaution.getResult(),
        });
        Object.assign(paramsPack, { survey: { items: itemsPack } });
        Object.assign(paramsPack, { signImg: signImg.getResult() });

        if (onlyCreate) {
            Object.assign(paramsPack, {
                displayName: basicDisplayNameRef.current.getResult(),
                cellPhone: basicCellPhoneRef.current.getResult(),
            });
        }
        return paramsPack;
    };

    const getBasicDataTitle = () => {
        return peopleType === peopleTypeMap.onlyJoin ? '新入會請填寫基礎資訊，提供入會申請書引用：' : '手動移入請填寫基礎資訊，提供移入申請書引用：';
    };

    const setNameRef = (el) => {
        if (el && el.getName) itemRadioRef.current[el.getName()] = el;
    };

    // 顯示定期去醫院看病
    const handleToggleChange = (e, checkedValue) => {
        let isShow = false;
        // 1 為 Yes 表示要顯示 其餘則不顯示
        if (checkedValue === '1') isShow = true;
        toggleRef.current.setOpen(isShow);
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => {
                    const { isOpen } = toggleRef.current;
                    if (isOpen && refIsRequiredError(sickNameRef, hospitalNameRef, precautionRef)) {
                        return true;
                    } else if (!refIsRequiredError(...Object.values(itemRadioRef.current))) {
                        return false;
                    }
                    return true;
                },
                getResult: () => {
                    return getAllParams();
                },
            };
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <Box className={clsx(isReadOnly && 'pb-3')}>
                {onlyCreate && (
                    <React.Fragment>
                        <Typography variant="h6" component="h3" className="mb-4">
                            {getBasicDataTitle()}
                        </Typography>
                        <Box className={clsx(isReadOnly && 'mb-3', 'p-3', 'bg-light')}>
                            <Grid spacing={3} container>
                                <Grid item xs={4}>
                                    <TextField
                                        key={'displayName'}
                                        ref={basicDisplayNameRef}
                                        label={'顯示名稱'}
                                        readOnly={isReadOnly}
                                        defaultValue={displayName}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        key={'cellPhone'}
                                        ref={basicCellPhoneRef}
                                        label={'手機'}
                                        readOnly={isReadOnly}
                                        defaultValue={cellPhone}
                                        maskType={'MOB'}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </React.Fragment>
                )}
                <Typography variant="h6" component="h3" className="mb-4">
                    Curves在此確認前來加入會員或免費體驗之顧客，其健康狀態適合使用本公司健身設施。
                </Typography>
                <Box component="ol" className="questions main">
                    <Box component="li" className="questions-item">
                        <RadioField
                            name={'1'}
                            defaultValue={getItemsValue(1, 'itemValue', items)}
                            ref={setNameRef}
                            label="您現在有定期去醫院看病嗎？"
                            row
                            labelDirection="row"
                            origin={'end'}
                            onChange={handleToggleChange}
                            readOnly={isReadOnly}
                            required
                        >
                            <RadioField.Radio value={1} label="Yes" />
                            <RadioField.Radio value={2} label="No" />
                        </RadioField>
                        <ToggleBox ref={toggleRef} defalutValue={getItemsValue(1, 'itemValue', items)}>
                            <Box className="p-4 bg-gray-100 ">
                                <Box className="row mb-2 round">
                                    <TextField
                                        autoComplete="off"
                                        name={'2'}
                                        defaultValue={getItemsValue(2, 'itemText', items)}
                                        ref={sickNameRef}
                                        label="病名"
                                        readOnly={isReadOnly}
                                        required
                                    />
                                    <TextField
                                        autoComplete="off"
                                        name={'3'}
                                        defaultValue={getItemsValue(3, 'itemText', items)}
                                        ref={hospitalNameRef}
                                        label="醫院名"
                                        readOnly={isReadOnly}
                                        required
                                    />
                                    <TextField
                                        name={'4'}
                                        defaultValue={getItemsValue(4, 'itemText', items)}
                                        ref={hospitalTelRef}
                                        label="醫院電話"
                                        readOnly={isReadOnly}
                                        maskType="TEL"
                                        required
                                    />
                                </Box>
                                <RadioField
                                    name={'5'}
                                    defaultValue={getItemsValue(5, 'itemValue', items)}
                                    ref={setNameRef}
                                    label="您的醫生知道您打算開始運動嗎？"
                                    className="mb-1"
                                    row
                                    labelDirection="row"
                                    readOnly={isReadOnly}
                                    origin={'end'}
                                >
                                    <RadioField.Radio value={1} label="Yes" />
                                    <RadioField.Radio value={2} label="No" />
                                </RadioField>
                                <TextField
                                    name={'6'}
                                    rows={3}
                                    multiline
                                    defaultValue={getItemsValue(6, 'itemText', items)}
                                    ref={precautionRef}
                                    label="目前運動時您可能特別需要注意的地方有哪些，請詳述："
                                    fullWidth
                                    readOnly={isReadOnly}
                                    required
                                />
                            </Box>
                        </ToggleBox>
                    </Box>
                    <Box component="li" className="questions-item">
                        <Typography variant="body1">下列內容中, 現在是否有或曾經符合的項目, 請選擇：</Typography>
                        <Box component="ol" className="questions">
                            <Box component="li" className="questions-item">
                                <MutiRadioTextField
                                    name={'7'}
                                    itemData={getItemsData(7, items)}
                                    ref={setNameRef}
                                    label="心臟病, 胸痛, 胸悶"
                                    isReadOnly={isReadOnly}
                                    required
                                />
                            </Box>
                            <Box component="li" className="questions-item">
                                <MutiRadioTextField
                                    name={'8'}
                                    itemData={getItemsData(8, items)}
                                    label="高血壓"
                                    ref={setNameRef}
                                    isReadOnly={isReadOnly}
                                    required
                                />
                            </Box>
                            <Box component="li" className="questions-item">
                                <MutiRadioTextField
                                    name={'9'}
                                    itemData={getItemsData(9, items)}
                                    label="高血脂肪"
                                    ref={setNameRef}
                                    isReadOnly={isReadOnly}
                                    required
                                />
                            </Box>
                            <Box component="li" className="questions-item">
                                <MutiRadioTextField
                                    name={'10'}
                                    itemData={getItemsData(10, items)}
                                    label="被醫生禁止運動"
                                    ref={setNameRef}
                                    isReadOnly={isReadOnly}
                                    required
                                />
                            </Box>
                            <Box component="li" className="questions-item">
                                <MutiRadioTextField
                                    name={'11'}
                                    itemData={getItemsData(11, items)}
                                    label="過去一年內有動過手術"
                                    ref={setNameRef}
                                    isReadOnly={isReadOnly}
                                    required
                                />
                            </Box>
                            <Box component="li" className="questions-item">
                                <MutiRadioTextField
                                    name={'12'}
                                    itemData={getItemsData(12, items)}
                                    label="現在，或3個月內打算懷孕"
                                    ref={setNameRef}
                                    isReadOnly={isReadOnly}
                                    required
                                />
                            </Box>
                            <Box component="li" className="questions-item">
                                <MutiRadioTextField
                                    name={'13'}
                                    itemData={getItemsData(13, items)}
                                    label="呼吸道或肺部疾病(例如：氣喘)"
                                    ref={setNameRef}
                                    isReadOnly={isReadOnly}
                                    required
                                />
                            </Box>
                            <Box component="li" className="questions-item">
                                <MutiRadioTextField
                                    name={'14'}
                                    itemData={getItemsData(14, items)}
                                    label="肌肉、關節、腰部病痛，或曾有現在仍感到不適的舊傷"
                                    ref={setNameRef}
                                    isReadOnly={isReadOnly}
                                    required
                                />
                            </Box>
                            <Box component="li" className="questions-item">
                                <MutiRadioTextField
                                    name={'15'}
                                    itemData={getItemsData(15, items)}
                                    label="糖尿病或甲狀腺疾病"
                                    ref={setNameRef}
                                    isReadOnly={isReadOnly}
                                    required
                                />
                            </Box>
                            <Box component="li" className="questions-item">
                                <MutiRadioTextField
                                    name={'16'}
                                    itemData={getItemsData(16, items)}
                                    label="抽煙、喝酒、藥物依賴"
                                    ref={setNameRef}
                                    isReadOnly={isReadOnly}
                                    required
                                />
                            </Box>
                            <Box component="li" className="questions-item">
                                <MutiRadioTextField
                                    name={'17'}
                                    itemData={getItemsData(17, items)}
                                    label="超重(以醫學觀點，宜減重條件為:腰圍80cm以上，或因超重引發疾病者)"
                                    ref={setNameRef}
                                    isReadOnly={isReadOnly}
                                    required
                                />
                            </Box>
                            <Box component="li" className="questions-item">
                                <MutiRadioTextField
                                    name={'18'}
                                    itemData={getItemsData(18, items)}
                                    label="膽固醇過高"
                                    ref={setNameRef}
                                    isReadOnly={isReadOnly}
                                    required
                                />
                            </Box>
                            <Box component="li" className="questions-item">
                                <MutiRadioTextField
                                    name={'19'}
                                    itemData={getItemsData(19, items)}
                                    label="有心血管疾病家族史"
                                    ref={setNameRef}
                                    isReadOnly={isReadOnly}
                                    required
                                />
                            </Box>
                            <Box component="li" className="questions-item">
                                <MutiRadioTextField
                                    name={'20'}
                                    itemData={getItemsData(20, items)}
                                    label="椎間盤突出或可能因肌肉訓練引起惡化的症狀"
                                    ref={setNameRef}
                                    isReadOnly={isReadOnly}
                                    required
                                />
                            </Box>
                            <Box component="li" className="questions-item">
                                <MutiRadioTextField
                                    name={'21'}
                                    itemData={getItemsData(21, items)}
                                    label="目前正在服用藥物"
                                    ref={setNameRef}
                                    isReadOnly={isReadOnly}
                                    required
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {!isReadOnly && (
                <React.Fragment>
                    <Box className="pb-3">
                        <Typography variant="h3" className="h6 mb-1 mt-5">
                            確認書
                        </Typography>
                        <Typography className="mark">請確實確認內容後, 在本確認書上簽名。</Typography>
                        <Box component="ol">
                            <Box component="li">
                                本人在加入 Curves會員或使用免費體驗前，對自我健康狀況充分了解且負責， 並認為本人目前健康狀況能夠負荷 Curves各項設施。
                            </Box>
                            <Box component="li">對於隱匿自身健康狀況造成傷害或發生急性病症等事故，本公司無需負賠償之責。</Box>
                        </Box>
                    </Box>
                    <Grid className="pb-3" container>
                        <Grid xs={6} item>
                            <Typography variant="body2" className="font-color-3">
                                未滿十八歲者請由監護人簽名
                            </Typography>
                            <SignBox ref={signImgRef} title="簽名" required />
                        </Grid>
                    </Grid>
                </React.Fragment>
            )}
        </React.Fragment>
    );
});

export default HealthDeclarationContent101;
