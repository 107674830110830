import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import FormControl from '../formControl/FormControl';
import FormLabel from '../formLabel/FormLabel';
import FormHelperText from '../formHelperText/FormHelperText';
import FormControlLabel from '../formControlLabel/FormControlLabel';
import FormGroup from '../formGroup/FormGroup';
import Checkbox from '../checkbox/Checkbox';
import { isValid } from '../../util/utils';

const CheckboxField = React.forwardRef((props, ref) => {
    const {
        isError: propsError = false,
        fullWidth = false,
        disabled = false,
        label,
        required = false,
        row = false,
        origin = 'start',
        onChange: onChangeProps,
        defaultChecked: defaultCheckedProps = [],
        defaultValue: defaultValueProps = [],
        children,
        helperText = '此選項為必填，請選擇其中一項',
        className,
        readOnly: readOnlyProps = false,
        name: nameProp = '',
        FormLabelTip = '',
    } = props;
    const checkedGroupRef = useRef([]);
    const [isError, setError] = useState(propsError);

    const formlabelProperties = {
        FormLabelProps: {
            labelTip: FormLabelTip,
        },
    };

    useImperativeHandle(
        ref,
        () => ({
            isError: () => {
                let source = required;
                if (required && checkedGroupRef && checkedGroupRef.current) {
                    source = !checkedGroupRef.current.some((target) => target.checked === true);
                }
                setError(source);
                return source;
            },
            getResult: () => {
                const source = checkedGroupRef.current.map((target) => {
                    return {
                        key: target.name || null,
                        value: target.value || null,
                        checked: !!target.checked,
                    };
                });
                return source;
            },
            getName: () => nameProp,
        }),
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            setError(propsError);
        },
        // eslint-disable-next-line
        [propsError]
    );

    return (
        <FormControl required={required} error={isError} fullWidth={fullWidth} disabled={disabled} className={className} readOnly={readOnlyProps}>
            {label && <FormLabel {...formlabelProperties}>{label}</FormLabel>}
            <FormGroup row={row} onChange={onChangeProps} origin={origin} disabled={disabled}>
                {React.Children.map(children, (child, idx) => {
                    if (!React.isValidElement(child)) return null;
                    const targetValue = defaultValueProps.find((v) => String(v) === String(child.props.value));
                    const defaultProperty = {
                        value: child.props.value,
                        label: child.props.label,
                        name: child.props.name,
                        defaultChecked: child.props.defaultChecked || defaultCheckedProps[idx] || isValid(targetValue),
                        checked: child.props.checked,
                        readOnly: child.props.readOnly || readOnlyProps,
                        control: <Checkbox inputRef={(el) => (checkedGroupRef.current[idx] = el)} onChange={child.props.onChange || onChangeProps} />,
                    };
                    return React.cloneElement(child, defaultProperty);
                })}
            </FormGroup>
            {isError && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
});

CheckboxField.Checkbox = (props) => {
    const { name: nameProps, value: valueProps, label: labelProps, readOnly: readOnlyProps = false, control, ...others } = props;
    return <FormControlLabel value={valueProps} label={labelProps} name={nameProps} control={control} readOnly={readOnlyProps} {...others} />;
};

export default CheckboxField;
