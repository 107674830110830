import useInBodyApi from '@apis/useInBodyApi';
import { useEffect, useState } from 'react';

// 取得量測紀錄
const useGetMeasureRecordsApi = ({ isFetchOnMount = true, referenceID, referenceType } = {}) => {
    const { getInterviewMeasureApi: doInterviewMeasureApi } = useInBodyApi();
    const [sourceData, setSourceData] = useState([]);

    const getInterviewMeasureApi = async () => {
        const resp = await doInterviewMeasureApi({ referenceID, type: referenceType });
        if (resp) {
            setSourceData(resp.measureData || []);
        }
    };

    useEffect(() => {
        if (isFetchOnMount && referenceID && referenceType) getInterviewMeasureApi();

        // eslint-disable-next-line
    }, [isFetchOnMount, referenceID, referenceType]);

    return { sourceData, getInterviewMeasureApi };
};

export default useGetMeasureRecordsApi;
