import React, { useState, useEffect, useImperativeHandle, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { isEmpty, refIsRequiredError } from '@util/utils';

const PrivateInformationBox = React.forwardRef((props, ref) => {
    let {
        defaultValue: defaultProps,
        placeholder: placeholderProps,
        value: valueProps,
        as: Component,
        onChange: onChangeProps,
        isError: propsError = false,
        required: requiredProps,
        ...rest
    } = props;

    const [valueState, setValue] = useState({
        defaultValue: '',
        value: '',
        placeholder: placeholderProps,
        key: '',
    });

    const [required, setRequired] = useState(false);
    const [isEncrypted, setIsEncrypted] = useState(false);

    const { placeholder, key, ...otherValue } = valueState;
    const isEditRef = useRef(false);
    const filedRef = useRef(null);

    const hasAsterisk = useCallback((value) => /\*+/gm.test(value), []);

    const handleChange = (e, ...rest) => {
        // change 時清空 加密資料的 placeholder
        if (!isEditRef.current) {
            setValue((old) => ({
                ...old,
                placeholder: '',
            }));
        }

        isEditRef.current = true;

        if (typeof onChangeProps === 'function') onChangeProps(e, ...rest);
    };

    useImperativeHandle(
        ref,
        () => ({
            isError: () => {
                // 有加密的話不檢查
                if (isEncrypted) return false;
                return refIsRequiredError(filedRef);
            },
            getResult: () => (isEditRef.current ? filedRef.current.getResult() : defaultProps || valueProps),
            getName: () => filedRef.current.name,
            getId: () => filedRef.current.id,
            node: () => filedRef.current,
        }),
        // eslint-disable-next-line
        [defaultProps, valueProps],
    );

    useEffect(() => {
        if (defaultProps === undefined && valueProps === undefined) {
            throw new Error('value 與 defaultValue 擇一設定');
        }

        const newObj = {
            defaultValue: '',
            value: '',
            placeholder: '',
        };

        // 有 * 的話(視為加密資料)，placeholder 顯示 *，並刷新元件
        if (hasAsterisk(defaultProps || '') || hasAsterisk(valueProps || '')) {
            newObj.placeholder = defaultProps || valueProps;
            newObj.key = uuid();
        }
        // defaultValue 有值，非控制元件所以要刷新元件
        else if (defaultProps !== undefined && defaultProps !== null) {
            newObj.defaultValue = defaultProps;
            newObj.key = uuid();
        }
        // value 有值，控制元件
        else if (valueProps !== undefined && valueProps !== null) {
            newObj.value = valueProps;
        }

        setValue((prev) => ({
            ...prev,
            ...newObj,
        }));
    }, [defaultProps, valueProps, hasAsterisk]);

    useEffect(() => {
        if (!isEmpty(valueState)) {
            const value = valueState.defaultValue || valueState.value;
            setRequired(requiredProps || Boolean(value));
        }
    }, [valueState, requiredProps]);

    useEffect(() => {
        setIsEncrypted(hasAsterisk(placeholder || ''));
    }, [placeholder, hasAsterisk]);

    return (
        <Component
            {...rest}
            key={key}
            ref={filedRef}
            isError={propsError}
            inputProps={{ placeholder }}
            required={!isEncrypted && required}
            onChange={handleChange}
            {...otherValue}
            {...rest}
        />
    );
});

PrivateInformationBox.propTypes = {
    as: PropTypes.elementType.isRequired,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    isError: PropTypes.bool,
    required: PropTypes.bool,
};

export default PrivateInformationBox;
