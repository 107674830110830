import React, { useState, useImperativeHandle, useRef, useEffect } from 'react';
import { RadioField, TextField } from '@common/components/';
import { refIsRequiredError, getRadioFieldResult } from '@util/utils';

const MutiRadioTextField = React.forwardRef((props, ref) => {
    const { itemData = {}, label = '', name, className, isReadOnly = false, required = false } = props;
    const [showItemText, setShowItemText] = useState(false);
    const itemValueRef = useRef(null);
    const itemTextRef = useRef(null);
    const handleChange = (e, typeValue) => {
        setShowItemText(String(typeValue) === '1');
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                const itemValue = getRadioFieldResult(itemValueRef.current.getResult(), 'value', '');
                const itemText = itemTextRef.current?.getResult() ?? '';
                return {
                    itemType: name,
                    itemValue,
                    itemText,
                };
            },
            getName: () => itemValueRef.current.getName(),
            isError: () => refIsRequiredError(itemValueRef, itemTextRef),
        }),
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            if (itemData && String(itemData.itemValue) === '1') {
                setShowItemText(true);
            }
        },
        // eslint-disable-next-line
        [itemData.itemValue]
    );

    return (
        <React.Fragment>
            <RadioField
                className={className}
                name={name}
                label={label}
                row
                defaultValue={itemData.itemValue || ''}
                labelDirection="row"
                ref={itemValueRef}
                origin={'end'}
                onChange={handleChange}
                readOnly={isReadOnly}
                required={required}
            >
                <RadioField.Radio value={1} label="Yes" />
                <RadioField.Radio value={2} label="No" />
            </RadioField>
            {showItemText && <TextField defaultValue={itemData.itemText || label} ref={itemTextRef} readOnly={isReadOnly} />}
        </React.Fragment>
    );
});

export default MutiRadioTextField;
