import React, { useEffect, useRef, useMemo } from 'react';
import { Route, Switch, useParams, Redirect } from 'react-router-dom';
import { InBodyProvider } from '@icoach/inbody/components/InBodyContext';
import { Box, PrivateRoute } from '@common/components/';
import { INBODY_EMPLOYEE_INTERVIEW_COMPARE_PATH, INBODY_EMPLOYEE_INTERVIEW_MEASURE_PATH } from '@icoach/router/routerPath';
import EmployeeInBodyInfoCard from '@icoach/inbody/employeeInbodyInterview/EmployeeInBodyInfoCard';
import EmployeeInBodyInterviewMeasureCompareTable from '@icoach/inbody/employeeInbodyInterview/EmployeeInBodyInterviewMeasureCompareTable';
import { referenceTypeMap } from '@icoach/inbody/staticData';
import InBodyInterviewDialogBox from '@icoach/inbody/components/InBodyInterviewDialogBox';
import EmployeeInBodyInterviewMeasureTable from '@icoach/inbody/employeeInbodyInterview/EmployeeInBodyInterviewMeasureTable';
import { isEmpty } from '@util/utils';
import useGetMeasureRecordsApi from '@util/hook/useGetMeasureRecordsApi';
import useGetEmployeeInformationApi from '@util/hook/useGetEmployeeInformationApi';

const EmployeeInBodyInterviewPage = () => {
    const { id: employeeID } = useParams();
    const { sourceData: EmployeeInfo } = useGetEmployeeInformationApi({ employeeID });
    const { sourceData: measureRecords, getInterviewMeasureApi } = useGetMeasureRecordsApi({
        referenceType: referenceTypeMap.employee,
        referenceID: employeeID,
    });

    // 過濾純面談的量身紀錄
    const filteredNonInterviewMeasureRecords = useMemo(() => {
        if (isEmpty(measureRecords)) return [];
        return measureRecords.filter(({ inbodyScore = 0 }) => inbodyScore);
    }, [measureRecords]);

    const dialogRef = useRef(null);
    const refreshRef = useRef(null);

    const setRefresh = (fn) => {
        refreshRef.current = fn;
    };

    const inbodyInterviewValue = {
        sourceData: measureRecords,
        filteredNonInterviewMeasureRecords,
        referenceType: referenceTypeMap.employee,
        peopleID: employeeID,
        peopleData: EmployeeInfo,
        isInterviewNotMeasure: false, // 教練不會純面談
        dialogRef: dialogRef,
        refresh: refreshRef.current,
        setRefresh,
    };

    useEffect(
        () => {
            setRefresh(getInterviewMeasureApi);
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <InBodyProvider value={inbodyInterviewValue}>
            <Box className="container main-container-spacing inbody-interview">
                <React.Fragment>
                    <EmployeeInBodyInfoCard className={'mb-3'} employeeID={employeeID} sourceData={EmployeeInfo} refresh={refreshRef.current} />
                    <Switch>
                        <PrivateRoute exact path={INBODY_EMPLOYEE_INTERVIEW_COMPARE_PATH(employeeID)} as={EmployeeInBodyInterviewMeasureCompareTable} />
                        <PrivateRoute exact path={INBODY_EMPLOYEE_INTERVIEW_MEASURE_PATH(employeeID)} as={EmployeeInBodyInterviewMeasureTable} />
                        <Route path="/">
                            <Redirect to={INBODY_EMPLOYEE_INTERVIEW_COMPARE_PATH(employeeID)} />
                        </Route>
                    </Switch>
                    <InBodyInterviewDialogBox ref={dialogRef} />
                </React.Fragment>
            </Box>
        </InBodyProvider>
    );
};

export default EmployeeInBodyInterviewPage;
